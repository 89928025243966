import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardData({ card }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderTCGPlayerPrices = (priceCategories) => {
    return Object.entries(priceCategories).map(([category, prices], index) => {
      return (
        <>
          <Typography variant="body1">{category}</Typography>
          {Object.entries(prices).map(([tier, price], index) => {
            return (
              <Typography key={index} variant="body2">
                {tier} - ${price}
              </Typography>
            );
          })}
        </>
      );
    });
  };

  const renderCardMarketPrices = (prices) => {
    return Object.entries(prices).map(([label, price], index) => {
      return (
        <Typography key={index} variant="body2">
          {label} - ${price}
        </Typography>
      );
    });
  };

  return (
    <Box display="flex">
      <Box p={2}>
        <Card>
          <CardHeader
            title={
              <Typography variant="body1" gutterBottom>
                {card.name} - {card.rarity} - {card.number}/
                {card.set.printedTotal}
              </Typography>
            }
            subheader={
              <Stack direction="row">
                <img
                  alt={`card set logo ${card.set.name}`}
                  height="20px"
                  src={`${card.set.images.logo}`}
                />
                <Box pl={2} pb={0.5}>
                  {card.set.name} - Released: {card.set.releaseDate}
                </Box>
              </Stack>
            }
          />
          <CardMedia
            component="img"
            image={card.images.small}
            alt={`image of ${card.name}`}
          />
          <CardActions disableSpacing>
            <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>Sell me your cards lol</CardContent>
          </Collapse>
        </Card>
      </Box>

      <Card>
        {card?.tcgplayer?.prices && (
          <CardContent>
            TCGPlayer Prices:
            {renderTCGPlayerPrices(card.tcgplayer.prices)}
            <a target="_blank" rel="noreferrer" href={card.tcgplayer.url}>
              See at TCG Player
            </a>
          </CardContent>
        )}
        {card?.cardmarket.prices && (
          <CardContent>
            CardMarket Prices:
            {renderCardMarketPrices(card.cardmarket.prices)}
            <a target="_blank" rel="noreferrer" href={card.cardmarket.url}>
              See at Card Market
            </a>
          </CardContent>
        )}
        <CardContent>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.ebay.co.uk/sch/i.html?_nkw=${card.name}+${card.number}/${card.set.printedTotal}`}
          >
            Search on Ebay
          </a>
        </CardContent>
      </Card>
    </Box>
  );
}
