import {
  ReactElement,
  Ref,
  forwardRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import CardList from "../components/CardList";
import CardData from "../components/CardData";
import Search from "../components/Search";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import pokemon from "pokemontcgsdk";
import { useParams } from "react-router-dom";
import useResizeAware from "react-resize-aware";

pokemon.configure({ apiKey: "eeddaaa9-9045-40ea-b04e-524c93d16eb8" });

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CardPage = () => {
  const params = useParams();
  const [search, setSearch] = useState(params?.setId ? "" : "Poliwhirl");
  const [cards, setCards] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const [resizeListener, sizes] = useResizeAware();

  const fetchCards = useCallback(
    async (setId = null) => {
      console.log("inside fetchCards", setId);
      const fetchedCards = await pokemon.card.all({
        q: `${!!setId ? `set.id:${params.setId}` : ""} ${
          !!search ? `name:${search}` : ""
        }`,
        orderBy: "set.releaseDate",
      });

      setCards(fetchedCards);
    },
    [params.setId, search]
  );

  useEffect(() => {
    fetchCards(params?.setId);
  }, [params?.setId, fetchCards]);

  const onSubmit = () => {
    console.log("submit");
    fetchCards();
  };

  const onClickCard = (cardId) => {
    const card = cards.find((card) => card.id === cardId);

    setSelectedCard(card);
    setOpen(true);
    console.log("card clicked");
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCard(null);
  };

  return (
    <>
      {resizeListener}

      <Search search={search} setSearch={setSearch} onSubmit={onSubmit} />

      {cards.length > 0 && (
        <Typography variant="h4">Total Results: {cards.length}</Typography>
      )}
      <CardList cards={cards} sizes={sizes} onClickCard={onClickCard} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"md"}
      >
        <Box>{selectedCard && <CardData card={selectedCard} />}</Box>
      </Dialog>
    </>
  );
};

export default CardPage;
