import Appbar from "./components/Appbar";
import Box from "@mui/material/Box";
import { Routes, Route } from "react-router-dom";
import CardsPage from "./pages/CardsPage";
import SetsPage from "./pages/SetsPage";

function App() {
  return (
    <Appbar>
      <Box p={1}>
        <Routes>
          <Route path="/" element={<CardsPage />} />
          <Route path="/set/:setId" element={<CardsPage />} />
          <Route path="/sets" element={<SetsPage />} />
        </Routes>
      </Box>
    </Appbar>
  );
}

export default App;
