import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";

function Row({ row }) {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <Link to={`/set/${row.id}`}>{row.name}</Link>
        </TableCell>
        <TableCell align="right">{row.series}</TableCell>
        <TableCell align="right">{row.releaseDate}</TableCell>
        <TableCell align="right">
          {
            <img
              style={{ maxWidth: "100px" }}
              alt={`logo of ${row.series}`}
              src={row.images.logo}
              height="50"
            />
          }
        </TableCell>
        <TableCell align="right">
          {
            <img
              alt={`symbol of ${row.series}`}
              src={row.images.symbol}
              height="50"
            />
          }
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function SetList({ sets }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Name </TableCell>
            <TableCell align="right">Series</TableCell>
            <TableCell align="right">Release Date</TableCell>
            <TableCell align="right">Logo</TableCell>
            <TableCell align="right">Symbol</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sets.map((set) => (
            <Row key={set.id} row={set} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
