import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Box from "@mui/material/Box";
import CardDisplay from "./CardDisplay";

export default function CardList({ cards, onClickCard, sizes }) {
  return (
    <ImageList
      cols={Math.floor(sizes.width / 250) - 1}
      sx={{ maxWidth: "90vw", width: sizes.width, height: sizes.height - 10 }}
    >
      {cards.map((card) => (
        <Box onClick={() => onClickCard(card.id)} key={card.id} p={1}>
          <ImageListItem>
            <CardDisplay card={card} />
            <ImageListItemBar
              title={`Rarity: ${card.rarity}`}
              actionIcon={
                <Box p={0.5}>
                  {card.number}/{card.set.printedTotal}
                </Box>
              }
              position="below"
            />
          </ImageListItem>
        </Box>
      ))}
    </ImageList>
  );
}
