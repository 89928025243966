import { useEffect, useCallback, useState } from "react";
import pokemon from "pokemontcgsdk";
import SetList from "../components/SetList";

const SetsPage = () => {
  const [cardSets, setCardSets] = useState([]);

  const fetchCardSets = useCallback(async () => {
    const fetchedCardSets = await pokemon.set.all({
      orderBy: "-releaseDate",
    });

    setCardSets(fetchedCardSets);
  }, []);

  useEffect(() => {
    fetchCardSets();
  }, [fetchCardSets]);
  return <SetList sets={cardSets} />;
};

export default SetsPage;
