const CardDisplay = ({ card }) => {
  return (
    <img
      alt={`pic of ${card.name}`}
      src={`${card.images.small}?w=120&fit=crop&auto=format`}
      srcSet={`${card.images.small}?w=248&fit=crop&auto=format&dpr=2 2x`}
    />
  );
};

export default CardDisplay;
