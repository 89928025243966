import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const Search = ({ search, setSearch, onSubmit }) => {
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <TextField
        id="outlined-name"
        label="Search for a Pokemon!"
        value={search}
        onChange={handleChange}
      />
    </Box>
  );
};

export default Search;
